* {
  font: Helvetica;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  display: block;
  flex-direction: column;
  padding-top: 5vh;
}

.form-label{
  color: black;
  font-size: 15px;
}

.form-input{
  border: 2px solid blue; 
  border-radius: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  width: 300px;
}

.logo {
  height: 75px;
  pointer-events: none;
}

.logo-small {
  height: 60px;
  pointer-events: none;
  align-self: center;
}

/* login page */

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}
.login-header {
  padding-top: 15vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: black;
}

.password-forget {
  color: blue;
  font-size: 12px;
}

.limited-time {
  color: red;
  font-weight: bold;
}

/* signup page */

.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}
.signup-header {
  padding-top: 15vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: black;
}

.password-hint {
  font-size: 12px;
}

.error-email-password {
  color: red;
  font-size: 12px;
}

.success-msg {
  color: green;
}

.error-msg {
  color: red;
  margin-top: 10px;
}

.btn {
  cursor: pointer;
  font-weight: bold;
}
.btn:active {
  transform: translateY(2px);
}

.btn:disabled {
  transform: translateY(0px);
  opacity: 50%;
}

.btn-login {
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  min-width: 100px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightyellow;
}

.btn-login:disabled {
  border: 2px solid lightblue;
  border-radius: 15px;
  color: lightblue;
  transform: translateY(0px);
  background-color: white;
}

/* home page */

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.btn-logout {
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  width: 100px;
  margin-top: 10px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightyellow;
}
.btn-info {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid blue;
  border-radius: 5px;
  background-color: white;
  height: 28px;
}
.btn-load-more {
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  width: 120px;
  margin-top: 10px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightyellow;
}
.btn-load-more:disabled {
  border: 2px solid lightblue;
  border-radius: 15px;
  color: lightblue;
  transform: translateY(0px);
  background-color: white;
}

input[type=file]::file-selector-button {
  cursor: pointer;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  width: 120px;
  margin-top: 10px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightgreen; 
}
/*
.btn-upload {
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  width: 300px;
  margin-top: 10px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightgreen;  
}
*/

.btn-streamed {
  cursor: pointer;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  width: 120px;
  margin-top: 10px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightsalmon; 
}

.top-right {
  margin-right:10px;
  align-self: flex-end;
}

.waiting-wheel {
  height: 12px;
  pointer-events: none;
}

/* recording section */

.recording-section {
  margin: auto;
  width: 50vw;
  margin-top: 10px;
}

.recording-box {
  cursor: pointer; 
  margin-bottom: 2px;
  border: 2px solid black;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.recording-box:active {
  transform: translateY(2px);
}
.recording-box-in-progress {
  cursor: pointer; 
  margin-bottom: 2px;
  border: 2px solid black;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  text-align: left;
  opacity: 50%;
}


.recording-delete {
  cursor: pointer; 
  text-align: center;
  width: 20px;
  color: red;
  background-color: white;
  align-self: flex-end;
  margin-right: 5px;
  border: 0;
  font-size: 10px;
}

.recording-title {
  padding-left: 10px;
  padding-top: 5px;
  font-size: 15px;
}

.recording-length {
  padding-left: 10px;
  padding-top: 5px;
  font-size: 12px;
  color: blue;
}

.recording-date {
  padding-left: 10px;
  padding-top: 5px;
  font-size: 12px;
  color: blue;
}

.recording-progress {
  color: red;
}

/*Edit screen*/

.edit-header {
  position: fixed;
  top: 0;
  background-color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  border: 1px solid gray;
  align-items: center;
  z-index: 1000;
}

.edit-header-btn {
  margin-left: 5px;
  margin-right: 5px;
  width: 40px;
  border: 1px solid blue;
  border-radius: 5px;
  background-color: white;
  height: 28px;
}

.edit-header-select {
  width: 50px;
  border: 1px solid blue;
  border-radius: 5px;
  height: 28px;
}

.edit-wider-btn {
  width: 75px;
}

.edit-header-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 10px;
  align-items: center;
}

.edit-header-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: blue;
}

.edit-header-time {
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: right;
}

.edit-header-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
}

.edit-header-add-text {
  border: 1px solid gray;
  width: 50px;
  font-size: 10px;
  padding: 2px;
}

.edit-icon {
  height: 15px;
}

.edit-audio {
  height: 20px;
}

.edit-top-margin {
  margin-top: 70px;
}

.edit-text {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
}

.edit-transcript {
  border: 1px solid blue;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}

.edit-loading {
  text-align: center;
}

/* Streamed */

.streamed-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.streamed-header {
  margin-top: 5vh;
}

.streamed-page-text {
  text-align: left;
  width: 90%;
  padding: 5px;
}

/* Record */

.record-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.record-header {
  margin-top: 5vh;
}

.record-page-text {
  width: 90%;
  padding: 5px;
}

.btn-choose-tab {
  cursor: pointer;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  width: 120px;
  margin-top: 10px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightsalmon; 
}

.recorder-red {
  color: red;
}

.recorder-progress {
  margin-top: 10px;
  height: 20px;
} 

.recorder-time {
  border: 1px solid black;
  margin-top: 10px;
  padding: 2px;
  width: 120px;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
}

.recorder-pause-finish-group {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.recorder-label-group {
  text-align: center;
  width: 60px;
}

.recorder-pause-btn {
  width: 40px;
  border: 2px solid blue;
  border-radius: 5px;
  background-color: white;
  height: 30px;
}

.recorder-finish-btn {
  width: 40px;
  border: 2px solid blue;
  border-radius: 5px;
  background-color: lightcoral;
  height: 30px;
}

.recorder-name-input {
  border: 1px solid black;
  margin-top: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  width: 200px;
}

.recorder-upload {
  cursor: pointer;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 2px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightgreen; 
}

.recorder-download {
  cursor: pointer;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 2px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white; 
}


.recorder-reset {
  cursor: pointer;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 15px;
  color: blue;
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 2px;  
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightcoral; 
}
